<template>
    <div class="min-h-screen flex flex-col">
        <HeaderSimulation
            class="z-50"
            page-resultat
            :color-styles="colorStyles"
            :from="from">
        </HeaderSimulation>

        <div class="mb-auto grow w-full grow-custom">
            <slot :from="from"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Valority",
    data: () => ({
        from: 'valority'
    }),
    computed: {
        isMobile() {
            return useNuxtApp().$isMobile?.value;
        },
        colorStyles() {
            const themes = {
                maslow: {
                    primary: '#F56E58',
                    secondary: '#FBECE3',
                    action: '#F56E58',
                    grey100: '#FAF8F7',
                    grey2: '#F6F4F3'
                },
                valority: {
                    primary: '#A58862',
                    secondary: '#EDE7E0',
                    action: '#1F2945',
                    grey100: '#F5F6F8',
                    grey2: '#F5F6F8'
                }
            };

            let styles = {
                'raw': {},
                'bg': {},
                'text': {},
                'border': {}
            };

            for (const [k, v] of Object.entries(themes[this.from])) {
                styles['raw'][k] = v;
                styles['text'][k] = 'color: ' + v + ';';
                styles['bg'][k] = 'background-color: ' + v + ';';
                styles['border'][k] = 'border-color: ' + v + ';';
            }

            return styles;
        }
    }
}
</script>

<style lang="scss">

</style>
